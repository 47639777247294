import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ImageModule } from "@core/app/image/image.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
	NgbDatepickerModule,
	NgbDropdownModule,
	NgbModalModule,
	NgbProgressbarModule,
	NgbTimepickerModule,
} from "@ng-bootstrap/ng-bootstrap";
import { FieldCheckboxComponent } from "./components/field-checkbox/field-checkbox.component";
import { FieldDateComponent } from "./components/field-date/field-date.component";
import { FieldDatetimeComponent } from "./components/field-datetime/field-datetime.component";
import { FieldFileMultipleComponent } from "./components/field-file-multiple/field-file-multiple.component";
import { FieldFileComponent } from "./components/field-file/field-file.component";
import { FieldMonthYearComponent } from "./components/field-month-year/field-month-year.component";
import { FieldNumberComponent } from "./components/field-number/field-number.component";
import { FieldRadioComponent } from "./components/field-radio/field-radio.component";
import { FieldSelectComponent } from "./components/field-select/field-select.component";
import { FieldTextMultipleComponent } from "./components/field-text-multiple/field-text-multiple.component";
import { FieldTextComponent } from "./components/field-text/field-text.component";
import { FieldTextareaComponent } from "./components/field-textarea/field-textarea.component";
import { FieldTimeComponent } from "./components/field-time/field-time.component";
import { FieldUserAuthGroupsComponent } from "./components/field-user-auth-groups/field-user-auth-groups.component";
import { FieldComponent } from "./components/field/field.component";
import { FieldsetComponent } from "./components/fieldset/fieldset.component";
import { FormComponent } from "./components/form/form.component";
import { LabelComponent } from "./components/label/label.component";

@NgModule({
	declarations: [
		FormComponent,
		FieldCheckboxComponent,
		FieldDateComponent,
		FieldDatetimeComponent,
		FieldFileMultipleComponent,
		FieldFileComponent,
		FieldMonthYearComponent,
		FieldNumberComponent,
		FieldRadioComponent,
		FieldSelectComponent,
		FieldTextMultipleComponent,
		FieldTextComponent,
		FieldTextareaComponent,
		FieldTimeComponent,
		FieldUserAuthGroupsComponent,
		FieldComponent,
		FieldsetComponent,
		LabelComponent,
	],
	imports: [
		CommonModule,
		FontAwesomeModule,
		FormsModule,
		ImageModule,
		NgbDatepickerModule,
		NgbTimepickerModule,
		NgbDropdownModule,
		NgbProgressbarModule,
		NgbModalModule,
		RouterModule,
	],
	exports: [
		FormComponent,
		FieldCheckboxComponent,
		FieldDateComponent,
		FieldDatetimeComponent,
		FieldFileMultipleComponent,
		FieldFileComponent,
		FieldMonthYearComponent,
		FieldNumberComponent,
		FieldRadioComponent,
		FieldSelectComponent,
		FieldTextMultipleComponent,
		FieldTextComponent,
		FieldTextareaComponent,
		FieldTimeComponent,
		FieldUserAuthGroupsComponent,
		FieldComponent,
		FieldsetComponent,
		LabelComponent,
	],
})
export class FormModule {}
