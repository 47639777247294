import { Component } from "@angular/core";
import { AppService } from "@core/app/app.service";
import { faPhoneAlt } from "@fortawesome/pro-light-svg-icons";

@Component({
	selector: "cm-footer",
	template: `
		<cm-skew class="bg-primary" [topSkew]="50" [nmargin]="true" style="padding-top: calc(50px + 2rem)"></cm-skew>
		<cm-skew [topSkew]="50" [nmargin]="true" class="bg-black text-white" style="padding-top: 50px">
			<div class="container-fluid p-3 p-md-5">
				<div class="row align-items-center">
					<div class="col-12 col-md-4">
						<img
							[src]="app.logo$ | async | image"
							alt="ASC Gearheads"
							class="img-fluid"
							style="width: 400px"
							loading="lazy"
						/>
						<div class="mt-3">
							<fa-icon [icon]="faPhoneAlt" [fixedWidth]="true" class="mr-2"></fa-icon>
							<a href="tel:{{ app.phoneNumber$ | async }}" class="text-white">{{
								app.phoneNumber$ | async
							}}</a>
						</div>
						<cm-gearheads-address
							[center]="false"
							[button]="false"
							iconSize="1x"
							class="mt-2"
							colorClass="text-white"
						></cm-gearheads-address>
					</div>
					<div class="col-12 col-md-5">
						<div class="text-uppercase font-weight-bold">Store</div>
						<div class="row">
							<div class="col-6">
								<div><a routerLink="/category/brakes" class="text-white">Brakes</a></div>
								<div>
									<a routerLink="/category/heating-and-cooling" class="text-white"
										>Heating and Cooling</a
									>
								</div>
								<div>
									<a routerLink="/category/engines-and-components" class="text-white"
										>Engines and Components</a
									>
								</div>
							</div>
							<div class="col-6">
								<div>
									<a routerLink="/category/ignitions-and-electrical" class="text-white"
										>Ignition and Electrical</a
									>
								</div>
								<div>
									<a routerLink="/category/safety-equipment" class="text-white">Safety Equipment</a>
								</div>
								<div>
									<a routerLink="/category/drivetrain" class="text-white"
										>Transmission & Drivetrain</a
									>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-3 text-center">
						Brought to You By<br />
						<br />
						<a href="https://allspeedcustoms.com/">
							<img
								src="https://dms.rvimg.com/s3static/10/asc-logo-l.png"
								alt="ASC Logo"
								class="img-fluid"
								width="400"
								height="67"
								loading="lazy"
							/>
						</a>
						<br />
						<br />
						<a routerLink="/refund-policy" class="text-white text-uppercase font-weight-bold"
							>Refund Policy</a
						><br />
						<a routerLink="/terms-of-service" class="text-white text-uppercase font-weight-bold"
							>Terms of Service</a
						><br />
						<a routerLink="/privacy-policy" class="text-white text-uppercase font-weight-bold"
							>Privacy Policy</a
						><br />
						Powered by
						<a href="https://designforcemarketing.com" target="_blank" class="text-white font-weight-bold"
							>Design Force Marketing</a
						>
					</div>
				</div>
			</div>
		</cm-skew>
	`,
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
})
export class FooterComponent {
	faPhoneAlt = faPhoneAlt;

	year = new Date().getFullYear();

	constructor(public app: AppService) {}
}
