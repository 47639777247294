import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { AppService } from "@core/app/app.service";
import { IMenuItem, Menu2Service } from "@core/app/menu2/menu2.service";
import { ViewportService } from "@core/app/shared/services/viewport.service";
import { UserService } from "@core/app/user.service";
import { faPhoneAlt } from "@fortawesome/pro-regular-svg-icons";
import { faBars, faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { Observable, of, ReplaySubject, Subscription } from "rxjs";
import { filter, map, shareReplay, switchMap, takeUntil } from "rxjs/operators";

// TODO: transfer data from SSR to prevent reloading
// TODO: add support for hiding submenus and revealing on click or hover
// Using ideas from https://www.w3schools.com/howto/howto_js_topnav_responsive.asp

// TODO: Move in to at-home module, specific to @Home

@Component({
	selector: "cm-menu",
	template: `
		<ng-container *ngIf="menu$ | async as menu">
			<nav class="position-relative" [ngClass]="{ mobile: mobile$ | async, show: mobileShowMenu }">
				<fa-icon [icon]="faBars" class="icon" role="button" tabindex="0" (click)="toggleMenu(menu)"></fa-icon>
				<ng-container *ngTemplateOutlet="submenu; context: { sub: menu, root: true }"></ng-container>
			</nav>

			<ng-template #submenu let-sub="sub" let-root="root">
				<ul
					class="submenu"
					[ngClass]="{
						'list-unstyled': style(root) === 'unstyled',
						'list-inline': style(root) === 'inline',
						shadow: !root && !(mobile$ | async)
					}"
				>
					<li class="menu-item text-nowrap" [ngClass]="{ 'list-inline-item': style(root) === 'inline' }">
						<a href="tel:{{ app.phoneNumber$ | async }}" class="d-block px-3 py-2 text-white">
							<fa-icon [icon]="faPhoneAlt"></fa-icon> {{ app.phoneNumber$ | async }}
						</a>
					</li>
					<li
						*ngFor="let item of sub"
						class="menu-item position-relative text-nowrap"
						[ngClass]="{
							'list-inline-item': style(root) === 'inline',
							show: item.show || (mobile$ | async)
						}"
						(mouseover)="item.show = true"
						(mouseleave)="item.show = false"
					>
						<a
							*ngIf="item.url && !item.children.length; else dropDown"
							routerLink="{{ item.url }}"
							routerLinkActive="active-link"
							[routerLinkActiveOptions]="{ exact: true }"
							class="d-block px-3 py-2 text-white"
							itemprop="url"
						>
							<span itemprop="name">{{ item.text }}</span>
						</a>
						<ng-template #dropDown>
							<a
								*ngIf="item.url"
								routerLink="{{ item.url }}"
								routerLinkActive="active-link"
								[routerLinkActiveOptions]="{ exact: true }"
								class="d-block px-3 py-2 text-white"
								itemprop="url"
							>
								<span class="flex-grow-1 mr-2" itemprop="name">{{ item.text }}</span>
								<fa-icon
									[icon]="faChevronDown"
									*ngIf="item.children.length"
									[ngClass]="{ 'd-none': mobile$ | async }"
								></fa-icon>
							</a>
							<div class="d-flex no-link px-3 py-2" *ngIf="!item.url" role="button">
								<span class="flex-grow-1 mr-2">{{ item.text }}</span>
								<fa-icon
									[icon]="faChevronDown"
									*ngIf="item.children.length"
									[ngClass]="{ 'd-none': mobile$ | async }"
								></fa-icon>
							</div>
							<ng-container *ngIf="item.children.length">
								<ng-container
									*ngTemplateOutlet="submenu; context: { sub: item.children, root: false }"
								></ng-container>
							</ng-container>
						</ng-template>
					</li>
					<ng-container *ngIf="root && mobile$ | async">
						<li
							class="menu-item position-relative text-nowrap"
							[ngClass]="{
								'list-inline-item': style(root) === 'inline',
								show: mobile$ | async
							}"
						>
							<a
								routerLink="/my-cart"
								routerLinkActive="active-link"
								[routerLinkActiveOptions]="{ exact: true }"
								class="d-block px-3 py-2 text-white"
								itemprop="url"
							>
								<span>My Cart</span>
							</a>
						</li>
						<ng-container *ngIf="userService.loggedIn$ | async">
							<li
								class="menu-item position-relative text-nowrap"
								[ngClass]="{
									'list-inline-item': style(root) === 'inline',
									show: mobile$ | async
								}"
							>
								<a
									routerLink="/my-account"
									routerLinkActive="active-link"
									[routerLinkActiveOptions]="{ exact: true }"
									class="d-block px-3 py-2 text-white"
									itemprop="url"
								>
									<span itemprop="name">Hello, {{ userService.firstName$ | async }}</span>
								</a>
							</li>
							<li
								class="menu-item position-relative text-nowrap"
								[ngClass]="{
									'list-inline-item': style(root) === 'inline',
									show: mobile$ | async
								}"
							>
								<a
									href="javascript:void(0)"
									(click)="userService.logOut()"
									class="d-block px-3 py-2 text-white"
								>
									<span itemprop="name">Sign Out </span>
								</a>
							</li>
						</ng-container>
						<ng-container *ngIf="!(userService.loggedIn$ | async)">
							<li
								class="menu-item position-relative text-nowrap"
								[ngClass]="{
									'list-inline-item': style(root) === 'inline',
									show: mobile$ | async
								}"
							>
								<a
									href="javascript:void(0)"
									(click)="logIn.emit(true)"
									class="d-block px-3 py-2 text-white"
								>
									<span itemprop="name">Sign In</span>
								</a>
							</li>
						</ng-container>
					</ng-container>
				</ul>
			</ng-template>
		</ng-container>
	`,
	styleUrls: ["./menu.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnChanges {
	@Input() appMenuid!: number;
	/**
	 * Style of root menu level
	 */
	@Input() rootStyle: "default" | "unstyled" | "inline" = "default";
	/**
	 * Style of submenu
	 */
	@Input() subStyle: "default" | "unstyled" | "inline" = "default";

	@Output() logIn = new EventEmitter(false);

	faPhoneAlt = faPhoneAlt;
	faBars = faBars;
	faChevronDown = faChevronDown;

	mobileShowMenu: boolean = false;
	itemFocusoutSub: Subscription | null = null;
	menuFocusoutSub: Subscription | null = null;
	ngOnDestroyRS = new ReplaySubject<void>();

	menu$: Observable<IMenuItemExt[] | null> = of(null);
	mobile$ = this.viewportService.windowSize$.pipe(map((w) => w < 1200));

	constructor(
		public app: AppService,
		private menuService: Menu2Service,
		private viewportService: ViewportService,
		public userService: UserService,
		@Inject(DOCUMENT) private document: Document,
		private router: Router,
		private http: HttpClient,
	) {
		this.mobile$.pipe(takeUntil(this.ngOnDestroyRS)).subscribe((mobile) => {
			if (mobile) {
				this.document.body.classList.add("mobile");
			} else {
				this.document.body.classList.remove("mobile");
			}
		});

		router.events
			.pipe(
				takeUntil(this.ngOnDestroyRS),
				filter((event) => event instanceof NavigationStart),
				switchMap(() => this.menu$),
			)
			.subscribe((menu) => {
				if (menu) {
					this.hideMenu(menu);
					this.document.body.classList.remove("nav-open");
				}
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.appMenuid) {
			this.menu$ = this.menuService.getMenu(changes.appMenuid.currentValue).pipe(
				map((items) => items && items.map((item) => ({ ...item, show: false }))),
				shareReplay(),
			);
		}
	}

	ngOnDestroy(): void {
		this.ngOnDestroyRS.next();
		this.ngOnDestroyRS.complete();
	}

	style(root: boolean) {
		return root ? this.rootStyle : this.subStyle;
	}

	hideMenu(menu: IMenuItemExt[]) {
		this.mobileShowMenu = false;
		this.recursiveHide(menu);
		this.document.body.classList.add("nav-open");
	}

	toggleMenu(menu: IMenuItemExt[]) {
		this.mobileShowMenu = !this.mobileShowMenu;

		if (!this.mobileShowMenu) {
			this.recursiveHide(menu);
			this.document.body.classList.remove("nav-open");
		} else {
			this.document.body.classList.add("nav-open");
		}
	}

	private recursiveHide(items: IMenuItemExt[]) {
		for (const item of items) {
			item.show = false;
			this.recursiveHide(item.children as IMenuItemExt[]);
		}
	}
}

interface IMenuItemExt extends IMenuItem {
	show: boolean;
}
