import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";

@Component({
	selector: "cm-skew",
	template: `
		<ng-content></ng-content>
	`,
	styles: [":host { display: block; }"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkewComponent {
	/**
	 * Height of slant. Positive values move the right side of the element down, and negative values move the left side
	 * of the element down.
	 */
	@Input() skew: number = 0;
	@Input() topSkew?: number;
	@Input() bottomSkew?: number;
	/**
	 * If split is set, positive skew values move the center down, and negative skew moves the outside edges down.
	 */
	@Input() split: boolean = false;
	@Input() padding: boolean = false;
	@Input() topPadding?: boolean;
	@Input() bottomPadding?: boolean;
	@Input() nmargin: boolean = false;
	@Input() units: string = "px";

	@HostBinding("style.clip-path")
	get clipPath(): SafeStyle {
		if (this.split) {
			return this.splitPath();
		} else {
			return this.path();
		}
	}
	@HostBinding("style.-webkit-clip-path")
	get webkitClipPath(): SafeStyle {
		return this.clipPath;
	}

	@HostBinding("style.padding-top")
	get paddingTop(): SafeStyle | undefined {
		const pad = this.topPadding === undefined ? this.padding : this.topPadding;
		if (pad) {
			return `${this.topSkewMax()}px`;
		}
	}

	@HostBinding("style.padding-bottom")
	get paddingBottom(): SafeStyle | undefined {
		const pad = this.bottomPadding === undefined ? this.padding : this.bottomPadding;
		if (pad) {
			return `${this.bottomSkewMax()}px`;
		}
	}

	@HostBinding("style.margin-top")
	get marginTop(): SafeStyle | undefined {
		if (this.nmargin) {
			return `-${this.topSkewMax()}px`;
		}
	}

	constructor(private sanitizer: DomSanitizer) {}

	private path() {
		const topLeftSkew = Math.max(0, -(this.topSkew || this.skew));
		const topRightSkew = Math.max(0, this.topSkew || this.skew);
		const bottomLeftSkew = Math.max(0, this.bottomSkew || this.skew);
		const bottomRightSkew = Math.max(0, -(this.bottomSkew || this.skew));
		return this.sanitizer.bypassSecurityTrustStyle(
			`polygon(0 ${topLeftSkew}${this.units}, 100% ${topRightSkew}${this.units}, 100% calc(100% - ${bottomRightSkew}${this.units}), 0 calc(100% - ${bottomLeftSkew}${this.units}))`,
		);
	}

	private splitPath() {
		const topOuterSkew = Math.max(0, -(this.topSkew || this.skew));
		const topInnerSkew = Math.max(0, this.topSkew || this.skew);
		const bottomOuterSkew = Math.max(0, this.bottomSkew || this.skew);
		const bottomInnerSkew = Math.max(0, -(this.bottomSkew || this.skew));
		return this.sanitizer.bypassSecurityTrustStyle(
			`polygon(0 ${topOuterSkew}${this.units}, 50% ${topInnerSkew}${this.units}, 100% ${topOuterSkew}${this.units}, 100% calc(100% - ${bottomOuterSkew}${this.units}), 50% calc(100% - ${bottomInnerSkew}${this.units}), 0 calc(100% - ${bottomOuterSkew}${this.units}))`,
		);
	}

	private topLeftSkew() {
		return Math.max(0, -(this.topSkew || this.skew));
	}

	private topRightSkew() {
		return Math.max(0, this.topSkew || this.skew);
	}

	private bottomLeftSkew() {
		return Math.max(0, this.bottomSkew || this.skew);
	}

	private bottomRightSkew() {
		return Math.max(0, -(this.bottomSkew || this.skew));
	}

	private topSkewMax() {
		return Math.max(this.topLeftSkew(), this.topRightSkew());
	}

	private bottomSkewMax() {
		return Math.max(this.bottomLeftSkew(), this.bottomRightSkew());
	}
}
