import { Injectable } from "@angular/core";
import { GTMService } from "./gtm.service";

@Injectable({ providedIn: "root" })
export class TrackingService {
	constructor(private GTMService: GTMService) {}

	/**
	 * Track route changes in the app
	 *
	 * @param route
	 */
	trackRouteChange() {
		// let google analytics know the url has changed
		const url = window.location.href.replace(window.location.origin, "");
		if (url.indexOf("admin") === -1 && url.indexOf("lead-management") === -1) {
			this.GTMService.trackEvent("ngRouteChange", {
				route: window.location.href.replace(window.location.origin, ""),
			});
		}
	}
}
