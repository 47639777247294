import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes, UrlSegment, UrlMatchResult, UrlMatcher } from '@angular/router';
import { CoreModule } from '../core.module';

import { RouteResolver } from "@core/app/route-resolver.service";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@core/environments/environment';
import { RootComponent } from "@core/app/pages/root/root.component";
import { PAGE_DATA } from "./page-data";
import { ToastNoAnimationModule } from 'ngx-toastr';
    import { PrerenderModule } from "shared";

export const routes: Routes = [
    {
        matcher: routeMatcher0 as UrlMatcher,
        loadChildren: () => import("./pages/page-admin/page-admin.module").then((m) => m.PageAdminModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Admin",
            pageTemplateId: 88,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/new-admin).*$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher1 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Account",
            pageTemplateId: 60,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/my-account)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher2 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Home",
            pageTemplateId: 39,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/)([0-9]+)?(?:\\?.*)?$",
            rewriteTo: "content_page_url=1&page=2",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher3 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Contact",
            pageTemplateId: 138,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/contact)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher4 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 255,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/product\\/(?:[^\\/]+))(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher5 as UrlMatcher,
        loadChildren: () => import("./pages/page-cart/page-cart.module").then((m) => m.PageCartModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 256,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/my-cart)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher6 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 257,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/products)(?:\\/([0-9]+))?(?:\\?.*)?$",
            rewriteTo: "content_page_url=1&page=2",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher7 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 258,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/post-order)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher8 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 261,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/dropship-tracking)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher9 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 270,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/store)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher10 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 327,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/category\\/(.+?))(?:\\/([0-9]+))?(?:\\?.*)?$",
            rewriteTo: "content_page_url=1&offer_cat=2&page=3",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher11 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 13,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/.+?)(?:(\\/[0-9]+))?(?:\\?.*)?$",
            rewriteTo: "content_page_url=1&page=2",
            stmtid: 85,
        }
    },
];

export function routeMatcher0(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/new-admin).*$/, segments, true); }
export function routeMatcher1(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/my-account)(?:\?.*)?$/, segments, false); }
export function routeMatcher2(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/)([0-9]+)?(?:\?.*)?$/, segments, false); }
export function routeMatcher3(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/contact)(?:\?.*)?$/, segments, false); }
export function routeMatcher4(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/product\/(?:[^\/]+))(?:\?.*)?$/, segments, false); }
export function routeMatcher5(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/my-cart)(?:\?.*)?$/, segments, false); }
export function routeMatcher6(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/products)(?:\/([0-9]+))?(?:\?.*)?$/, segments, false); }
export function routeMatcher7(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/post-order)(?:\?.*)?$/, segments, false); }
export function routeMatcher8(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/dropship-tracking)(?:\?.*)?$/, segments, false); }
export function routeMatcher9(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/store)(?:\?.*)?$/, segments, false); }
export function routeMatcher10(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/category\/(.+?))(?:\/([0-9]+))?(?:\?.*)?$/, segments, false); }
export function routeMatcher11(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/.+?)(?:(\/[0-9]+))?(?:\?.*)?$/, segments, false); }

export function routeMatcher(regex: RegExp, segments: UrlSegment[], hasChildRouter: boolean): UrlMatchResult | null {
    if (regex.test("/" + segments.map(x => x.path).join("/"))) {
        if (hasChildRouter) {
            return { consumed: [segments[0]] };
        } else {
            return { consumed: segments };
        }
    } else {
        return null;
    }
}

@NgModule({
    declarations: [RootComponent],
    imports: [
        CommonModule,
        CoreModule.forRoot(),
        ToastNoAnimationModule.forRoot({ positionClass: 'toast-top-center' }),
        PrerenderModule,
        RouterModule.forRoot(routes, {
            initialNavigation: "enabledBlocking",
            scrollPositionRestoration: "enabled",
            anchorScrolling: "enabled",
            onSameUrlNavigation: "reload",
        }),
        ServiceWorkerModule.register("./ngsw-worker.js", { enabled: environment.production }),
        
    ],
    providers: [{ provide: "PAGE_DATA", useValue: PAGE_DATA }, ],
    entryComponents: [RootComponent],
    bootstrap: [RootComponent],
})
export class AppModule { }
