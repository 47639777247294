import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ModalModule, UtilModule as UtilModuleShared } from "shared";
import { AccountModule } from "../account/account.module";
import { FormModule } from "../form/form.module";
import { GearheadsModule } from "../gearheads/gearheads.module";
import { ImageModule } from "../image/image.module";
import { InventoryModule } from "../inventory/inventory.module";
import { LayoutModule } from "../layout/layout.module";
import { Map2Module } from "../map2/map2.module";
import { Pagination2Module } from "../pagination2/pagination2.module";
import { SearchModule } from "../search/search.module";
import { SliderModule } from "../slider/slider.module";
import { UtilModule } from "../util/util.module";
import { MenuComponent } from "./menu/menu.component";
import { HempMfrTrackingNumberComponent } from "./mfr-tracking-number/mfr-tracking-number.component";
import { HempAccountColComponent } from "./my-account/account-col.component";
import { HempAccountComponent } from "./my-account/account.component";
import { HempOfferImgsComponent } from "./offer/offer-imgs.component";
import { HempContactOffer } from "./offer/offer.component";
import { HempPostOrderComponent } from "./post-order/post-order.component";
import { HempListingGridComponent } from "./products/listing-grid.component";
import { HempProductsComponent } from "./products/products.component";
import { HempSearchFilterComponent } from "./search-filter/search-filter.component";
import { HempWishlistComponent } from "./wishlist.component";

@NgModule({
	imports: [
		AccountModule,
		CommonModule,
		FontAwesomeModule,
		LayoutModule,
		Map2Module,
		SliderModule,
		UtilModule,
		FormModule,
		FormsModule,
		ImageModule,
		ModalModule,
		RouterModule,
		InventoryModule,
		Pagination2Module,
		NgbModule,
		UtilModuleShared,
		GearheadsModule,
		SearchModule,
	],
	exports: [
		HempAccountComponent,
		HempProductsComponent,
		HempContactOffer,
		HempSearchFilterComponent,
		HempPostOrderComponent,
		HempMfrTrackingNumberComponent,
		MenuComponent,
	],
	declarations: [
		HempAccountColComponent,
		HempContactOffer,
		HempOfferImgsComponent,
		HempSearchFilterComponent,
		HempPostOrderComponent,
		HempAccountComponent,
		HempProductsComponent,
		HempListingGridComponent,
		HempWishlistComponent,
		HempMfrTrackingNumberComponent,
		MenuComponent,
	],
})
export class HempOilModule {}
