import { IEnvironmentPartial } from "./environment-types";

export const environment: IEnvironmentPartial = {
	dealerInfo: {
		name: "hemp-oil",
		homeBreadcrumb: "Hemp Oil Distro",
		themeData: {
			dealerAccent: null,
		},
	},
};
