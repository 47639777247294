import { CommonModule, Location } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ButtonModule } from "@core/app/button/button.module";
import { GroupByPipe } from "@core/app/group-by.pipe";
import { ImageModule } from "@core/app/image/image.module";
import { InventoryModule } from "@core/app/inventory/inventory.module";
import { LayoutModule } from "@core/app/layout/layout.module";
import { SearchModule } from "@core/app/search/search.module";
import { SliderModule } from "@core/app/slider/slider.module";
import { SlugifyPipe } from "@core/app/slugify.pipe";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ModalModule } from "shared";
import { EditWidgetComponent } from "../edit-widget/edit-widget.component";
import { FormModule } from "../form/form.module";
import { GearheadsModule } from "../gearheads/gearheads.module";
import { HempOilModule } from "../hemp-oil/hemp-oil.module";
import { LazyModule } from "../lazy/lazy.module";
import { UtilModule } from "../util/util.module";

@NgModule({
	imports: [
		CommonModule,
		FontAwesomeModule,
		FormsModule,
		NgbModule,
		RouterModule,
		RouterModule,
		ImageModule,
		InventoryModule,
		SearchModule,
		SliderModule,
		LayoutModule,
		ButtonModule,
		UtilModule,
		FormModule,
		ModalModule,
		HempOilModule,
		GearheadsModule,
		LazyModule,
	],
	declarations: [GroupByPipe, SlugifyPipe, EditWidgetComponent],
	exports: [
		CommonModule,
		FontAwesomeModule,
		FormsModule,
		GroupByPipe,
		NgbModule,
		RouterModule,
		SlugifyPipe,
		ImageModule,
		InventoryModule,
		SearchModule,
		SliderModule,
		LayoutModule,
		FormModule,
		ModalModule,
		HempOilModule,
		GearheadsModule,
		LazyModule,
		EditWidgetComponent,
	],
	providers: [Location],
})
export class SharedModule {}
