import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { iterObj } from "@common/iter";
import { IStmtResults } from "@model/stmt-results";
import { BehaviorSubject, combineLatest, of, Subject } from "rxjs";
import { delay, first, map, shareReplay, startWith, switchMap, take, withLatestFrom } from "rxjs/operators";
import { iter, tuple } from "shared/common";
import { UserService } from "../user.service";

@Injectable({ providedIn: "root" })
export class OffersService {
	private refreshWishlistS = new Subject<void>();

	cartBS = new BehaviorSubject(loadCart());

	cart$ = this.cartBS.asObservable().pipe(delay(1000), shareReplay(1));

	totalItems$ = this.cart$.pipe(
		map((cart) =>
			iterObj(cart)
				.map(([offer_variationid, value]: any) => value.qty)
				.sum(),
		),
	);

	sidebarBS = new BehaviorSubject<{ offer_variationid: string | null; qty: number }>({
		offer_variationid: null,
		qty: 0,
	});

	offerVariations$ = this.cart$.pipe(
		switchMap((cart) =>
			this.http.post("/api/statement/GetOfferVariations", {
				vars: {
					offer_variationids: iterObj(cart)
						.map(([offer_variationid]) => offer_variationid)
						.toArray(),
				},
			}),
		),
		map((res: any) =>
			iter(res.results)
				.map((row: any) => tuple(row.offer_variationid.toString(), row))
				.toMap(),
		),
		shareReplay(1),
	);

	subtotal$ = combineLatest([this.offerVariations$, this.user.isWholesaleCustomer$]).pipe(
		withLatestFrom(this.cart$),
		map(([[offers, wholesale], cart]) =>
			iterObj(cart)
				.map(
					([offer_variationid, qty]) =>
						this.price(wholesale, offers.get(offer_variationid)) * (qty as any).qty,
				)
				.sum(),
		),
	);

	wishlist$ = this.refreshWishlistS.pipe(
		startWith(null),
		switchMap(() => this.user.loggedIn$),
		switchMap((loggedIn) =>
			loggedIn ? this.http.post("/api/statement/GetWishlist", {}).pipe(map((res: any) => res.results)) : of([]),
		),
		map((res) => new Map<number, any>(res.map((row: any) => tuple(row.offerid, row)))),
		shareReplay(1),
	);

	stateprov$ = this.http
		.post("/api/statement/stateprovSelect", { vars: { countryid: 1 } })
		.pipe(map((response: IStmtResults<any>) => response.results));

	offers$ = this.cart$.pipe(
		switchMap((cart) =>
			this.http.post("/api/statement/GetOfferVariations", {
				vars: {
					offer_variationids: iterObj(cart)
						.map(([offer_variationid]: any) => offer_variationid)
						.toArray(),
				},
			}),
		),
		map((res: any) =>
			iter(res.results)
				.map((row: any) => tuple(row.offer_variationid.toString(), row))
				.toMap(),
		),
		shareReplay(1),
	);

	cantOrder$ = this.offers$.pipe(
		map((offers) =>
			iter(offers.values())
				.filter((row) => row.current_stock === 0)
				.nth(0)
				.isSome(),
		),
	);

	constructor(private http: HttpClient, private user: UserService) {}

	addToCart(offer_variationid: number, qty: number, cardMessage: string | null = null) {
		this.refreshCart();
		const id = offer_variationid.toString();
		const tempQty = (this.cartBS.value[id] ? this.cartBS.value[id].qty : 0) + qty;
		this.cartBS.value[id] = { qty: tempQty, cardMessage: cardMessage };
		localStorage.setItem("cart", JSON.stringify(this.cartBS.value));
		this.cartBS.next(this.cartBS.value);
		this.sidebarBS.next({ offer_variationid: id, qty: qty });
		return this.cartBS.asObservable().pipe(delay(1000), shareReplay(1));
	}

	removeFromCart(idx: number) {
		this.refreshCart();
		delete this.cartBS.value[idx.toString()];
		localStorage.setItem("cart", JSON.stringify(this.cartBS.value));
		this.cartBS.next(this.cartBS.value);
		return this.cartBS.asObservable().pipe(delay(1000), shareReplay(1));
	}

	delCart() {
		localStorage.removeItem("cart");
		this.cartBS.next({});
		return this.cartBS.asObservable().pipe(delay(1000), shareReplay(1));
	}

	saveCart() {
		for (const [_, item] of iterObj(this.cartBS.value)) {
			const itemAny = item as any;
			if (itemAny.qty <= 0) {
				itemAny.qty = 1;
			}
		}
		localStorage.setItem("cart", JSON.stringify(this.cartBS.value));
		this.cartBS.next(this.cartBS.value);
	}

	toggleWishlistItem(offerid: number) {
		if (!this.user.loggedIn()) {
			throw new Error("user must be logged in to add to wishlist");
		}

		const vars = { vars: { offerid } };
		return this.wishlist$.pipe(
			first(),
			switchMap((wishlist) =>
				wishlist.has(offerid)
					? this.http.post("/api/statement/RemoveFromWishlist", vars)
					: this.http.post("/api/statement/AddToWishlist", vars),
			),
			switchMap(() => this.refreshWishlist()),
		);
	}

	private refreshWishlist() {
		const ret = this.wishlist$.pipe(take(1));
		this.refreshWishlistS.next();
		return ret;
	}

	private refreshCart() {
		this.cartBS.next(JSON.parse(localStorage.getItem("cart") || "{}"));
	}

	price(wholesale: boolean, variation: any) {
		return variation
			? wholesale && variation.variation_wholesale_price
				? variation.variation_wholesale_price && Number(variation.variation_wholesale_price)
				: variation.variation_price && Number(variation.variation_price)
			: 0;
	}
}

function loadCart() {
	let cartAny = JSON.parse((typeof localStorage !== "undefined" && localStorage.getItem("cart")) || "{}");
	if (Array.isArray(cartAny)) {
		const cart = cartAny as any[];
		cartAny = iter(cartAny).fold({} as any, (acc: any, item: any) => {
			const id = item.offer_variationid.toString();
			const tmpQty = (acc[id].qty || 0) + item.qty;
			acc[id] = { qty: tmpQty, cardMessage: item.cardMessage };
			return acc;
		});
		localStorage.setItem("cart", JSON.stringify(cartAny));
	}
	return cartAny as any;
}
