import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SliderImplFadeComponent } from "./impl/fade.component";
import { SliderImplTranslateComponent } from "./impl/translate.component";
import { SliderPaginationDefaultComponent } from "./pagination/default";
import { SliderPaginationProgressComponent } from "./pagination/progress";
import { SlideDirective } from "./slide.directive";
import { SliderComponent } from "./slider.component";

@NgModule({
	declarations: [
		SlideDirective,
		SliderComponent,
		SliderImplTranslateComponent,
		SliderImplFadeComponent,
		SliderPaginationDefaultComponent,
		SliderPaginationProgressComponent,
	],
	imports: [CommonModule, FontAwesomeModule],
	exports: [SliderComponent, SlideDirective],
})
export class SliderModule {}
