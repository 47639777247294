import { DOCUMENT } from "@angular/common";
import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	OnInit,
	Renderer2,
	RendererFactory2,
	ViewChild,
} from "@angular/core";
import { Meta, MetaDefinition, Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import { ImagePipe } from "@common/pipes/image";
import { CacheService } from "@core/app/cache.service";
import { tuple } from "@core/app/common/iter";
import { TrackingService } from "@core/app/tracking.service";
import { IPageData } from "@model/page-data";
import { ToastContainerDirective, ToastrService } from "ngx-toastr";
import { filter, map } from "rxjs/operators";
import { isPrerendering } from "shared";
import { environment } from "../../../environments/environment";

@Component({
	selector: "cm-root",
	templateUrl: "./root.component.html",
	providers: [ImagePipe],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RootComponent implements OnInit {
	bodyClasses: string[] = [];
	siteLogo: any = null;
	pageBodyClass: string | null = null;
	urlBodyClass: string | null = null;
	webPageSchema: string = "http://schema.org/WebPage";

	private renderer: Renderer2;

	@ViewChild(ToastContainerDirective, { static: false }) toastContainer!: ToastContainerDirective;

	constructor(
		private cacheService: CacheService,
		private trackingService: TrackingService,
		private imagePipe: ImagePipe,
		private toastrService: ToastrService,
		private route: ActivatedRoute,
		private meta: Meta,
		private title: Title,
		rendererFactor: RendererFactory2,
		router: Router,
		swUpdate: SwUpdate,
		@Inject(DOCUMENT) private document: Document,
		@Inject("PAGE_DATA") public pageData: IPageData,
	) {
		this.renderer = rendererFactor.createRenderer(null, null);
		this.siteLogo = this.pageData.appInfo.data.siteLogo;
		const siteName = this.pageData.appInfo.data.siteName;

		this.meta.addTags([
			{ property: "og:locale", content: "en_US" },
			{ property: "og:site_name", content: siteName },
			{ property: "fb:app_id", content: this.pageData.fbAppId },
			{ name: "twitter:card", content: "summary_large_image" },
			{ name: "twitter:site", content: "@" + siteName },
			{ name: "twitter:creator", content: "@" + siteName },
			{ name: "msapplication-TileColor", content: "#2B5797" },
			{ name: "msapplication-TileImage", content: "/mstile-144x144.png" },
			{ name: "msapplication-config", content: "/browserconfig.xml" },
		]);

		router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map((event) => {
					event = event as NavigationEnd;

					let child = this.route.firstChild;
					while (child) {
						if (child.firstChild) {
							child = child.firstChild;
						} else if (child.snapshot.data && child.snapshot.data.routeData) {
							return tuple(event, child.snapshot.data);
						} else {
							return tuple(event, null);
						}
					}
					return tuple(event, null);
				}),
			)
			.subscribe(([_event, data]) => {
				const metaInfo = data!.routeData.stmt ? data!.routeData.stmt.results[0] : null;
				if (metaInfo) {
					this.trackingService.trackRouteChange();
				}

				this.setupMetaInfo(data, metaInfo);
				this.setupAddThis(metaInfo);
				this.setPageBodyClass(data!.bodyClass);
			});

		if (swUpdate.isEnabled) {
			swUpdate.checkForUpdate().catch((err) => console.error("error when checking for update", err));
		}

		this.setupThemeClass();

		const localCache = this.cacheService.init("localStorage");
		const notify = localCache.get("notify");
		if (notify !== null) {
			setTimeout(() => this.toastrService.info(notify), 2000);
			localCache.remove("notify");
		}

		if (!isPrerendering()) {
			(function(w: any, d, s, l, i) {
				w[l] = w[l] || [];
				w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
				const f = d.getElementsByTagName(s)[0];
				const j: any = d.createElement(s);
				const dl = l != "dataLayer" ? "&l=" + l : "";
				j.async = true;
				j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
				f.parentNode!.insertBefore(j, f);
			})(window, document, "script", "dataLayer", this.pageData.appInfo.data.googleAnalyticsCode);
		}
	}

	setBodyClass(className: any, enabled?: boolean) {
		for (const cssClass of this.bodyClasses) {
			this.document.body.classList.remove(cssClass);
		}
		const index = this.bodyClasses.indexOf(className);

		if (!enabled && index !== -1) {
			this.bodyClasses.splice(index, 1);
		} else if (enabled && index === -1 && className.trim() !== "") {
			this.bodyClasses.push(className);
		}

		for (const cssClass of this.bodyClasses) {
			this.document.body.classList.add(cssClass);
		}
	}

	setPageBodyClass(newBodyClass: any) {
		if (this.pageBodyClass) {
			this.setBodyClass(this.pageBodyClass, false);
		}
		this.setBodyClass(newBodyClass, true);
		this.pageBodyClass = newBodyClass;
	}

	setUrlBodyClass(newBodyClass: any) {
		if (this.urlBodyClass) {
			this.setBodyClass(this.urlBodyClass, false);
		}
		this.setBodyClass(newBodyClass, true);
		this.urlBodyClass = newBodyClass;
	}

	setupAddThis(metaInfo: any) {
		if (typeof window !== "undefined" && metaInfo) {
			(window as any).addthis_share = {
				description: metaInfo.meta_desc,
				title: metaInfo.title,
				url: "https://" + this.pageData.host + metaInfo.content_page_url,
			};
		}
	}

	setupMetaInfo(data: any, metaInfo: any) {
		const metaTags: { [key: string]: MetaDefinition } = {
			robots: { name: "robots", content: "" },
			ogType: { property: "og:type", content: "" },
			ogTitle: { property: "og:title", content: "" },
			ogDescription: { property: "og:description", content: "" },
			ogUrl: { property: "og:url", content: "" },
			ogImage: { property: "og:image", content: "" },
			ogImageType: { property: "og:image:type", content: "" },
			ogImageAlt: { property: "og:image:alt", content: "" },
			twitterDescription: { name: "twitter:description", content: "" },
			twitterTitle: { name: "twitter:title", content: "" },
			twitterImage: { name: "twitter:image", content: "" },
			keywords: { name: "keywords", content: "" },
			description: { name: "description", content: "" },
		};

		if (metaInfo) {
			const follow = metaInfo.meta_robots_nofollow === 1 ? "nofollow" : "follow";
			const index = metaInfo.meta_robots_noindex === 1 || data.routeData.params.page ? "noindex" : "index";
			metaTags.robots.content = `${follow}, ${index}`;

			// TODO: generate title and page_desc correctly in the database so the year doesn't need to be stripped out
			// here
			if (metaInfo.hide_year && metaInfo.hide_year === 1) {
				metaInfo.title = metaInfo.title.replace(metaInfo.model_year, "");
				metaInfo.page_desc = metaInfo.page_desc.replace(metaInfo.model_year, "");
			}

			if (data.routeData.params.page) {
				if (data.routeData.params.page > 1) {
					metaInfo.title += " p. " + data.routeData.params.page;
				}

				metaInfo.meta_desc = "";
				metaInfo.meta_kywd = "";
			}

			this.title.setTitle(metaInfo.title);

			const pageImage = this.imagePipe.transform(metaInfo.img_dir + metaInfo.img_file, "l");
			let fileName = pageImage ? pageImage : this.siteLogo!.url;

			if (fileName.indexOf("-l.") === -1) {
				fileName = fileName.replace(/-i\.|-t\.|-s\.|-m\.|-o\.|-lc\./gi, ".");
				const index = fileName.lastIndexOf(".");
				if (-1 !== index) {
					fileName = fileName.substr(0, index) + "-l" + fileName.substr(index);
				}
			}

			metaTags.ogType.content = metaInfo.og_type || "article";
			metaTags.ogTitle.content = metaInfo.title;
			metaTags.ogDescription.content = metaInfo.meta_desc;
			metaTags.ogUrl.content = `https://${this.pageData.host}${metaInfo.canonical}`;
			metaTags.ogImage.content = fileName;
			metaTags.ogImageAlt.content = metaInfo.img_alt;
			metaTags.twitterDescription.content = metaInfo.meta_desc;
			metaTags.twitterTitle.content = metaInfo.title;
			metaTags.twitterImage.content = fileName;
			metaTags.keywords.content = metaInfo.meta_kywd || "";
			metaTags.description.content = metaInfo.meta_desc || "";

			this.setImageType(fileName, metaTags);

			// The WebPage structured data item needs to change depending on the page
			if (metaInfo.content_page_url === "/about") {
				this.webPageSchema = "http://schema.org/AboutPage";
			} else if (metaInfo.content_page_url === "/contact") {
				this.webPageSchema = "http://schema.org/ContactPage";
			} else {
				this.webPageSchema = "http://schema.org/WebPage";
			}

			if (metaInfo.canonical !== null) {
				this.setUrlBodyClass(metaInfo.canonical.split("/").join(""));
				const canonicalUrl = `https://${this.pageData.host}${metaInfo.canonical}`;
				try {
					const alternate = this.renderer.selectRootElement("link.alternate");
					alternate.setAttribute("href", canonicalUrl);
				} catch (e) {
					const head = this.document.getElementsByTagName("head")[0];
					const link = this.document.createElement("link");
					link.rel = "alternate";
					link.href = canonicalUrl;
					link.setAttribute("class", "alternate");
					head.appendChild(link);
				}
				try {
					const canonical = this.renderer.selectRootElement("link.canonical");
					canonical.setAttribute("href", canonicalUrl);
				} catch (e) {
					const head = this.document.getElementsByTagName("head")[0];
					const link = this.document.createElement("link");
					link.rel = "canonical";
					link.href = canonicalUrl;
					link.setAttribute("class", "canonical");
					head.appendChild(link);
				}
			} else {
				try {
					const item = this.renderer.selectRootElement("link.alternate");
					item.remove();
				} catch (e) {
					// ignore
				}
				try {
					const item = this.renderer.selectRootElement("link.canonical");
					item.remove();
				} catch (e) {
					// ignore
				}
			}

			if (metaInfo.enable_amp === 1 && this.pageData.enableAmp) {
				metaInfo.ampUrl = `https://amp.${this.pageData.host}${metaInfo.canonical}`;
				try {
					const amp = this.renderer.selectRootElement("link.ampUrl");
					amp.setAttribute("href", metaInfo.ampUrl);
				} catch (e) {
					const head = this.document.getElementsByTagName("head")[0];
					const link = this.document.createElement("link");
					link.rel = "amphtml";
					link.href = metaInfo.ampUrl;
					link.setAttribute("class", "ampUrl");
					head.appendChild(link);
				}
			} else {
				try {
					const item = this.renderer.selectRootElement("link.ampUrl");
					item.remove();
				} catch (e) {
					// ignore
				}
			}
		}

		for (const tag of Object.values(metaTags)) {
			this.meta.updateTag(tag);
		}
	}

	setupThemeClass() {
		const domainClass = environment.dealerInfo.name.replace(".", "-");

		this.setBodyClass(domainClass, true);

		const theme = environment.dealerInfo.themeData;
		if (theme?.dealerAccent) {
			this.meta.addTag({ name: "theme-color", content: theme.dealerAccent.bg });
		}
	}

	setImageType(fileName: string, metaTags: { [key: string]: MetaDefinition }) {
		const ext = fileName.split(".").pop();
		switch (ext) {
			case "jpg":
			case "jpe":
			case "jpeg":
				metaTags.ogImageType.content = "image/jpeg";
				break;
			case "gif":
				metaTags.ogImageType.content = "image/gif";
				break;
			case "png":
				metaTags.ogImageType.content = "image/png";
				break;
			case "webp":
				metaTags.ogImageType.content = "image/webp";
				break;
			case "svg":
				metaTags.ogImageType.content = "image/svg+xml";
				break;
			default:
				metaTags.ogImageType.content = "unknown";
		}
	}

	ngOnInit() {
		this.toastrService.overlayContainer = this.toastContainer;
	}
}
