import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

@Component({
	selector: "cm-login-modal",
	template: `
		<cm-modal [show]="show" (showChange)="showChange.next($event)">
			<ng-template cmModal>
				<div class="container-fluid">
					<div class="row">
						<h2 class="col-12 bg-primary text-white flex-center m-0 py-1">Log In</h2>
						<div class="col-12 p-sm-5 p-3 flex-center">
							<cm-account (loggedIn)="loggedIn.next()"></cm-account>
						</div>
					</div>
				</div>
			</ng-template>
		</cm-modal>
	`,

	styles: [
		`
			:host ::ng-deep .modal-inner > .container {
				padding-left: 0;
				padding-right: 0;
				width: 500px;
				max-width: 95vw;
			}
			:host ::ng-deep .close-btn {
				color: #000;
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginModalComponent {
	@Input() show!: boolean;
	@Output() loggedIn = new EventEmitter<void>();
	@Output() showChange = new EventEmitter<boolean>();

	LoginState = LoginState;
	faCheck = faCheck;
}

enum LoginState {
	Login,
	Register,
	ForgotPass,
}
