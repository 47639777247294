import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { StarListingComponent } from "@core/app/star-listing/star-listing.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ModalFileUploadComponent } from "../admin/components/modal-file-upload/modal-file-upload.component";
import { DropzoneComponent } from "../dropzone/dropzone.component";
import { ImageModule } from "../image/image.module";
import { LoginComponent } from "../login/login.component";
import { ReplaceLineBreaksPipe } from "../shared/pipes/nl2br.pipe";
import { SkewComponent } from "./components/skew.component";

@NgModule({
	declarations: [
		DropzoneComponent,
		ModalFileUploadComponent,
		LoginComponent,
		ReplaceLineBreaksPipe,
		SkewComponent,
		StarListingComponent,
	],
	imports: [CommonModule, FormsModule, NgbModule, FontAwesomeModule, ImageModule],
	exports: [
		DropzoneComponent,
		ModalFileUploadComponent,
		LoginComponent,
		ReplaceLineBreaksPipe,
		SkewComponent,
		StarListingComponent,
	],
})
export class LayoutModule {}
