import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ImageModule } from "../image/image.module";
import { SliderModule } from "../slider/slider.module";
import { AddressComponent } from "./components/address";
import { CatListComponent } from "./components/cat-list.component";
import { CatSliderComponent } from "./components/cat-slider.component";
import { CatsRowComponent } from "./components/cats-row.component";
import { ContactButtonsComponent } from "./components/contact-buttons";
import { OfferCardComponent } from "./components/offer-card.component";
import { OfferSliderESComponent } from "./components/offer-slider-es.component";
import { OfferSliderComponent } from "./components/offer-slider.component";

@NgModule({
	declarations: [
		OfferSliderComponent,
		CatsRowComponent,
		AddressComponent,
		ContactButtonsComponent,
		OfferCardComponent,
		CatSliderComponent,
		CatListComponent,
		OfferSliderESComponent,
	],
	imports: [CommonModule, FontAwesomeModule, SliderModule, ImageModule, RouterModule],
	exports: [
		OfferSliderComponent,
		CatsRowComponent,
		AddressComponent,
		ContactButtonsComponent,
		OfferCardComponent,
		CatSliderComponent,
		CatListComponent,
		OfferSliderESComponent,
	],
})
export class GearheadsModule {}
