import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, Inject, Renderer2 } from "@angular/core";
import { OffersService } from "@core/app/hemp-oil/offers.service";
import { faCheckCircle, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { of } from "rxjs";
import { filter, map, shareReplay, switchMap, tap } from "rxjs/operators";

@Component({
	selector: "cm-cart-sidebar",
	template: `
		<div *ngIf="cartInfo$ | async as cartInfo" class="widget container-fluid">
			<div class="row py-4 bg-primary text-white header">
				<div class="col-10">
					<fa-icon [icon]="faCheckCircle"></fa-icon> {{ cartInfo.qty }} item<ng-container
						*ngIf="cartInfo.qty > 1"
						>s</ng-container
					>
					added to cart
				</div>
				<div class="col-2 text-right">
					<fa-icon role="button" class="pointer-cursor" [icon]="faTimes" (click)="resetBar()"></fa-icon>
				</div>
			</div>
			<div class="row align-items-center py-2 bg-white">
				<div class="col-4">
					<div class="embed-responsive embed-responsive-16by9">
						<img
							[src]="cartInfo.img_dir + cartInfo.img_file || 's3static/25/noimage.jpg' | image: 's'"
							[alt]="cartInfo.img_alt"
							class="embed-responsive-item"
							style="object-fit:contain"
						/>
					</div>
				</div>
				<div class="col-8">
					<a [routerLink]="cartInfo.content_page_url">
						<h6 class="h4-lg m-0">{{ cartInfo.offer }}</h6>
					</a>
					<small *ngIf="cartInfo.variants !== 'Default: Default'"><br />{{ cartInfo.variants }}</small>
				</div>
			</div>
			<div class="row py-2 border-top border-bottom bg-white">
				<div class="col-12 text-center">
					<strong class="mx-1">Cart subtotal</strong
					><i
						>({{ offers.totalItems$ | async }} item<ng-container *ngIf="(offers.totalItems$ | async) > 1"
							>s</ng-container
						>)</i
					><strong class="mx-1">{{ offers.subtotal$ | async | currency }}</strong>
				</div>
				<div class="col-12 py-1 text-center">
					<a routerLink="/my-cart" (click)="resetBar()" class="btn btn-primary shadow rounded mx-1"
						>View cart</a
					>
					<button (click)="resetBar()" class="btn btn-light shadow rounded text-black mx-1">
						Continue Shopping
					</button>
				</div>
			</div>
			<div class="text-center py-2 font-weight-bold">Customers also bought these products</div>
			<div class="row justify-content-center">
				<div *ngFor="let offer of relatedOffers$ | async" class="col-6 px-1 mb-1">
					<a [routerLink]="offer.content_page_url" class="w-100 h-100 flex-center bg-white rounded shadow">
						<div class="text-uppercase w-100 h-100 d-flex flex-column">
							<div class="embed-responsive embed-responsive-1by1">
								<img
									[src]="offer.img_dir + offer.img_file || 's3static/25/noimage.jpg' | image: 'm'"
									srcset="{{offer.img_dir + offer.img_file || 's3static/25/noimage.jpg' | image: 's'}} 1x, {{offer.img_dir + offer.img_file || 's3static/25/noimage.jpg' | image: 'm'}} 2x"
									class="embed-responsive-item rounded"
									style="object-fit: cover"
									loading="lazy"
								/>
							</div>
							<div class="p-3 flex-grow-1 d-flex flex-column">
								<small class="font-weight-bold text-center flex-grow-1">{{ offer.offer }}</small>
								<div class="text-center py-1">
									{{ offer.variation_price_min | currency: undefined:"symbol" }}
									<ng-container *ngIf="offer.variation_price_min !== offer.variation_price_max">
										-
										{{ offer.variation_price_max | currency: undefined:"symbol" }}</ng-container
									>
								</div>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
	`,
	styles: [
		`
			.widget {
				position: fixed;
				top: 0;
				right: 0;
				width: 400px;
				background: var(--grey);
				height: 100vh;
				max-width: calc(100% - 30px);
				z-index: 1050;
				overflow: scroll;
				box-shadow: 1.5px 1.5px 3px 0 rgba(0, 0, 0, 0.5);
			}

			.header {
				position: sticky;
				top: 0;
				z-index: 1;
			}
		`,
	],
})
export class CartSidebarComponent {
	faCheckCircle = faCheckCircle;
	faTimes = faTimes;

	cartInfo$ = this.offers.sidebarBS.pipe(
		tap((sidebar) => {
			if (sidebar.offer_variationid) {
				this.renderer.addClass(this.document.body, "overflow-hidden");
			} else {
				this.renderer.removeClass(this.document.body, "overflow-hidden");
			}
		}),
		switchMap((sidebar) => {
			if (sidebar.offer_variationid) {
				return this.http
					.post("/api/statement/GetOfferVariations", {
						vars: { offer_variationids: [sidebar.offer_variationid] },
					})
					.pipe(map((res: any) => ({ ...res.results[0], qty: sidebar.qty })));
			} else {
				return of(null);
			}
		}),
		shareReplay(1),
	);

	relatedOffers$ = this.cartInfo$.pipe(
		filter((cartInfo) => !!cartInfo),
		switchMap((cartInfo) =>
			this.http.post(`/api/statement/GetOffersByOrderCount`, {
				vars: { site_offer_catids: [cartInfo.site_offer_catid], exclude: [cartInfo.offerid] },
			}),
		),
		map((res: any) => res.results),
	);

	resetBar() {
		this.offers.sidebarBS.next({ offer_variationid: null, qty: 0 });
	}

	constructor(
		public offers: OffersService,
		private http: HttpClient,
		private renderer: Renderer2,
		@Inject(DOCUMENT) private document: Document,
	) {}
}
