<cm-header></cm-header>
<!-- <div class="header-offset"></div> -->
<cm-edit-widget></cm-edit-widget>
<span itemscope [attr.itemtype]="webPageSchema">
	<div class="site-wrapper" itemscope itemtype="http://schema.org/WebPageElement" itemprop="mainContentOfPage">
		<router-outlet></router-outlet>
	</div>
</span>
<cm-footer></cm-footer>
<div toastContainer></div>
<cm-cart-sidebar></cm-cart-sidebar>
