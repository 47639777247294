import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { AppService } from "@core/app/app.service";
import { faMapMarkerAlt } from "@fortawesome/pro-light-svg-icons";

@Component({
	selector: "cm-gearheads-address",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="d-flex" [ngClass]="{ 'flex-column': center }">
			<div class="d-flex align-items-center mb-3 flex-grow-1" [ngClass]="{ 'justify-content-center': center }">
				<fa-icon
					[icon]="faMapMarkerAlt"
					[size]="iconSize"
					class="mr-2 {{ colorClass }}"
					[fixedWidth]="true"
				></fa-icon>
				<a [href]="app.addressLink$ | async" target="_blank" [class]="colorClass">
					{{ app.addressLine1$ | async }}<br />
					{{ app.addressLine2$ | async }}
				</a>
			</div>
			<div *ngIf="button" class="text-center">
				<a [href]="app.addressLink$ | async" class="btn btn-primary">Get Directions</a>
			</div>
		</div>
	`,
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
})
export class AddressComponent {
	@Input() center: boolean = true;
	@Input() button: boolean = true;
	@Input() iconSize: string = "2x";
	@Input() colorClass: string = "text-primary";

	faMapMarkerAlt = faMapMarkerAlt;

	constructor(public app: AppService) {}
}
