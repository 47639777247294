import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Inject, Renderer2 } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { ViewportService } from "@core/app/shared/services/viewport.service";
import { UserService } from "@core/app/user.service";
import { faSearch, faShoppingCart } from "@fortawesome/pro-light-svg-icons";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { faBars, faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { IPageData } from "@model/page-data";
import { NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, Observable, of } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { iter, tuple } from "shared/common";
import { AppService } from "../app.service";
import { OffersService } from "../hemp-oil/offers.service";
import { RestService } from "../rest.service";

@Component({
	selector: "cm-header",
	template: `
		<header id="nav-header" class="header shadow">
			<div class="container-fluid py-3">
				<div class="row flex-nowrap" itemscope itemtype="http://schema.org/WPHeader">
					<div class="col-6 col-md-10 col-xl-3 pl-xl-5 d-flex align-items-center">
						<a routerLink="/" class="text-nowrap">
							<img
								[src]="app.logo$ | async | image: 's'"
								alt="{{ app.dealerInfo$ | async }}"
								class="logo img-fluid"
							/>
						</a>
					</div>
					<div class="col-6 col-md-2 col-xl-6" style="font-size: 1.25rem">
						<div *ngIf="!(mobile$ | async)" class="flex-center">
							<cm-search
								placeholder="Search parts, part numbers, tools, equipment, accessories, & more!"
								buttonClass="primary px-2 py-1"
								[buttonIcon]="faSearch"
								[searchFn]="doTypeaheadSearch"
								(result)="selected($event)"
								(search)="search($event)"
								class="flex-grow-1"
							></cm-search>
						</div>
						<div
							class="flex-md-center text-uppercase"
							[ngClass]="{
								'text-right': (mobile$ | async)
							}"
						>
							<cm-menu
								[appMenuid]="1"
								rootStyle="inline"
								subStyle="unstyled"
								itemscope
								itemtype="http://schema.org/SiteNavigationElement"
								(logIn)="showLogin = true"
								class=""
								[ngClass]="{
									'bg-secondary w-100 mt-3 mb-n5': !(mobile$ | async)
								}"
								style="z-index: 1;"
							></cm-menu>
						</div>
					</div>
					<div *ngIf="!(mobile$ | async)" class="col-3 pr-5 flex-center flex-column">
						<a routerLink="/my-cart" class="btn btn-secondary text-white">
							<fa-icon [icon]="faShoppingCart"></fa-icon>
							Shopping Cart
							<ng-container *ngIf="offers.subtotal$ | async as subtotal"
								>| {{ subtotal | currency }}</ng-container
							>
						</a>
						<div class="text-right text-white mt-2">
							<fa-icon [icon]="faUser"></fa-icon>
							<ng-container *ngIf="user.loggedIn$ | async; else login">
								<span class="mx-2">Hello, {{ friendlyName$ | async }}</span>
								<a routerLink="/my-account" class="text-uppercase font-weight-bold mx-2 text-white"
									>Account</a
								>
								<a
									href="javascript:void(0)"
									role="button"
									class="text-uppercase font-weight-bold mx-2 text-white"
									(click)="user.logOut()"
									>Sign Out</a
								>
							</ng-container>
							<ng-template #login>
								<a
									href="javascript:void(0)"
									role="button"
									class="text-uppercase font-weight-bold mx-2 text-white"
									(click)="showLogin = true"
									>Login/Register</a
								>
							</ng-template>
						</div>
					</div>
				</div>
				<cm-search
					*ngIf="mobile$ | async"
					placeholder="Search parts, part numbers, tools, equipment, accessories, & more!"
					buttonClass="primary"
					[searchFn]="doTypeaheadSearch"
					(result)="selected($event)"
					(search)="search($event)"
					[focusFirst]="true"
					class="d-block mt-3"
				></cm-search>
			</div>
		</header>

		<cm-login-modal [(show)]="showLogin" (loggedIn)="showLogin = false"></cm-login-modal>
	`,
	styleUrls: ["./header.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
	faBars = faBars;
	faCaretDown = faCaretDown;
	faSearch = faSearch;
	faShoppingCart = faShoppingCart;
	faUser = faUser;

	showLogin = false;
	showProductsBS = new BehaviorSubject(false);

	friendlyName$ = this.user.user$.pipe(map((user) => (user ? user.first_name || user.user_login : "")));
	mobile$ = this.viewport.bp.down.lg$;

	cats$ = this.rest
		.init("statement")
		.post$("GetAllSiteOfferCats", {}, "GetAllSiteOfferCats")
		.pipe(
			map((res) =>
				iter(res.results)
					.map((row: any) => tuple(row.site_offer_cat_ofid, row))
					.toGroupMap()
					.toMap(),
			),
		);

	constructor(
		public app: AppService,
		public user: UserService,
		public offers: OffersService,
		private http: HttpClient,
		@Inject("PAGE_DATA") public pageData: IPageData,
		private viewport: ViewportService,
		private renderer: Renderer2,
		private rest: RestService,
		private router: Router,
		@Inject(DOCUMENT) private document: Document,
	) {
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
			this.showProductsBS.next(false);
			this.renderer.removeClass(this.document.body, "modal-open");
		});
	}

	doTypeaheadSearch = (text: string): Observable<any[]> => {
		if (text) {
			return this.http
				.post("/api/elasticsearch/queryOffers", {
					query: {
						bool: {
							must: [
								{
									multi_match: {
										query: text,
										type: "most_fields",
										fields: ["vendor_part_num^2", "brand^2", "name"],
										fuzziness: "AUTO",
									},
								},
							],
							filter: [
								{ term: { site_configid: "$site_configid" } },
							],
						},
					},
				})
				.pipe(
					map((response: any) =>
						response.hits.hits.map((hit: any) => ({
							offerid: hit._id,
							label: hit._source.name,
							href: hit._source.content_page_url,
						})),
					),
				);
		} else {
			return of([]);
		}
	};

	selected(selectedItem: NgbTypeaheadSelectItemEvent) {
		this.router.navigateByUrl(selectedItem.item.href);
		this.renderer.removeClass(this.document.body, "modal-open");
		this.showProductsBS.next(false);
	}

	toggleMenu() {
		if (this.showProductsBS) {
			this.renderer.removeClass(this.document.body, "modal-open");
		} else {
			this.renderer.addClass(this.document.body, "modal-open");
		}
		this.showProductsBS.next(!this.showProductsBS.value);
	}

	search(searchString: string) {
		if (searchString) {
			this.router.navigateByUrl("/products?keywords=" + searchString);
		} else {
			this.router.navigateByUrl("/products");
		}
	}

	subcatIds(id: number, cats: Map<any, any[]> | null) {
		if (!cats) {
			return null;
		}
		const subs = cats.get(id);
		if (!subs) {
			return null;
		}
		return [id].concat(subs.map((x) => x.site_offer_catid)).join(",");
	}
}
